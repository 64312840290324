import Axios, { AxiosInstance } from 'axios';
import { API_ENDPOINTS } from './ApiEndpoints';
import i18n from 'i18n';
Axios.defaults.withCredentials = true;

export type ApiResponse<T = any> = {
  data: T;
};

const excludedUnauthenticatedRoutes = [
  API_ENDPOINTS.PUBLIC.LOGIN,
  API_ENDPOINTS.PRIVATE.GET_USER,
];
class AxiosFactory {
  public axiosInstance: AxiosInstance;
  private languages: { [key: string]: string } = {
    'us-EN': 'en',
    'fr-FR': 'fr',
    'es-ES': 'es',
  };
  constructor() {
    const axiosInstance = Axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      withCredentials: true,
    });

    axiosInstance.interceptors.request.use((config) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          'Content-Language': this.languages[i18n.language] || 'en',
        },
      };
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      function (error) {
        if (
          error.response.status === 401 &&
          !excludedUnauthenticatedRoutes.some((url) => {
            let testUrl = url;
            const responseUrl = error.response.config.url.split('?include=')[0];
            if (!url.startsWith('/')) {
              testUrl = '/' + testUrl;
            }
            return responseUrl === testUrl;
          })
        ) {
          window.location.href = '/login?redirect=' + window.location.pathname;
        }
        if (error.response.status === 403) {
          return Promise.reject({ error: error?.response });
        }
        return Promise.reject(error);
      },
    );

    this.axiosInstance = axiosInstance;
  }

  getInstance = (): AxiosInstance => {
    return this.axiosInstance;
  };
}

export default new AxiosFactory();
