const LITERALS = {
  MENU: {
    MANAGER_COOKIES: 'Administrar Cookies',
    PRIVACY_POLICY: 'Política de privacidad',
    FAQ: 'FAQ',
  },
  BUTTON: {
    LEARN_MORE: 'Aprende más',
    SAVE: 'Guardar',
    ACCEPT: 'Aceptar',
    DECLINE: 'Rechazar',
    ACCEPT_ALL: 'Aceptar todas',
    DECLINE_ALL: 'Rechazar todas',
    HERE: 'aquí',
    PRIVACY_POLICY: 'Política de privacidad',
  },
  CONSENTS: {
    BANNER: {
      TITLE: 'Política de privacidad',
      DESCRIPTION:
        'Recopilamos y procesamos su información personal para los siguientes propósitos. Para obtener más información, lea nuestras',
    },
    MODAL: {
      TITLE: 'Configuración de cookies',
      DESCRIPTION:
        'Algunas características esenciales simplemente no funcionarán sin cookies y tener otras cookies desactivadas puede afectar seriamente la forma en que podrá disfrutar de nuestros servicios. Verifique su configuración de cookies a continuación y active las cookies con las que esté satisfecho. Para obtener más información, lea nuestras',
      COOKIES: {
        NECESSARY: {
          TITLE: 'Cookies estrictamente necesarias',
          DESCRIPTION:
            'Las cookies "estrictamente necesarias" están activadas de forma predeterminada, pero se pueden desactivar en la configuración de su navegador.',
          APPS: {
            OWN: {
              TITLE: 'Administrador de privacidad',
              REQUIRED: 'siempre requerido',
              DESCRIPTION:
                'Guarde esta configuración para visitas recurrentes.',
            },
          },
        },
        PERFORMANCE: {
          TITLE: 'Cookies de rendimiento',
          DESCRIPTION:
            'Cookies de rendimiento: nos ayudan a asegurarnos de que el sitio web funcione correctamente y corregir cualquier error. Y nos dejaron probar diferentes ideas.',
          APPS: {
            GOOGLE_TAG_MANAGER: {
              TITLE: 'Google Tag Manager',
              DESCRIPTION:
                'Ayúdanos a comprender cómo las personas usan nuestros servicios, para que podamos mejorarlos.',
            },
            YOUTUBE: {
              TITLE: 'Youtube',
              DESCRIPTION:
                'Cookie configurada por YouTube para rastrear las vistas de videos incrustados en los sitios de Skouting.',
            },
          },
        },
      },
    },
  },
  SKTOKES: {
    TABLE: {
      TITLE: 'Transacciones del mes',
      HEAD: {
        DATE: 'FECHA',
        MINUTES: 'MINUTOS',
        SERVICES: 'SERVICIOS',
      },
    },
  },
  LICENSE: {
    BUY: 'Comprar',
    FILTER: 'Filtros',
    STATUS: {
      ALL: 'Todas',
      ACTIVE: 'Activa',
      INACTIVE: 'Inactiva',
      EXPIRED: 'Expirada',
      REVOKED: 'Revocada',
      ACTIVE_REVOKED: 'Activa revocada',
      INACTIVE_REVOKED: 'Inactiva revocada',
      EXPIRED_REVOKED: 'Expirada revocada',
    },
    TABLE: {
      HEAD: {
        LICENSE_AVAILABLE: 'Licencia disponible',
        LICENSE_TYPE: 'Tipo',
        CREATE_DATE: 'Creación',
        ACTIVATION_DATE: 'Activación',
        VALID_UNTIL: 'Validez',
        STATUS: 'Estado',
        ACTIONS: 'Acciones',
      },
    },
    ACTION: {
      COPIED_CLIPBOARD: 'Licencia copiada al portapapeles',
    },
    MESSAGE: {
      NOT_ACTIVATED: 'No activada',
    },
  },
  ENGARDE_SERVICES: {
    ORGANISM: 'Organismo',
    EVENT: 'Evento',
    COMPETITION: 'Competición',
    PHASE: 'Fase',
    PHASE_OPTIONS: {
      NOT_SPECIFIED: 'No especificada',
      POULES: 'Poules',
      TABLEAU: 'Tableau',
    },
  },
  SUBSCRIPTION: {
    PAYMENT_MODE: {
      MONTHLY: 'Por mes / Pago mensual',
      ANNUAL: 'Anualidad',
    },
    BUTTON: {
      COUPON_CODE: 'Código del Cupón',
      COMING_SOON: 'Próximamente',
      ALREADY_PURCHASED: 'Ya Comprado',
      INCLUDED: 'Incluido',
      BUY_NOW: 'Comprar Ahora',
    },
    PLANS: {
      BASIC: {
        TITLE: 'Básico',
        BENEFIT_1: 'Mira, comenta y comparte',
        BENEFIT_2: 'Acceder a la base de datos de videos',
        BENEFIT_3: 'Crear Video Analisis',
        BENEFIT_4: 'Acceder a la barra de control de video',
        BENEFIT_5: 'Crear grupos',
        BENEFIT_6: 'Estadísticas avanzadas',
        BENEFIT_7: '3.000 SK Tokens mensuales / 20.000 Tokens anuales',
        BENEFIT_8: '2 % de descuento en Tokens',
      },
      EXPERT: {
        TITLE: 'Experto',
        BENEFIT_1: 'Todas las ventajas de Premium +',
        BENEFIT_2: 'Licencia Skouting Creator',
        BENEFIT_3: 'Almacenamiento de 100 GB',
        BENEFIT_4: '35.000 SK Tokens mensuales / 150.000 SK Tokens anuales',
        BENEFIT_5: '5% de descuento en Tokens',
      },
      PREMIUM: {
        TITLE: 'Premium',
        BENEFIT_1: 'Todos los beneficios de Basic+',
        BENEFIT_2: 'Almacenamiento de 10 GB',
        BENEFIT_3: 'Informes detallados y estadísticas',
        BENEFIT_4: 'Acceso al análisis de IA',
        BENEFIT_5: '2 análisis al mismo tiempo',
        BENEFIT_6: 'Licencia Skouting Creator',
        BENEFIT_7: '7.000 SK Tokens Mensuales / 60.000 SK Tokens Anuales',
        BENEFIT_8: '3 % de descuento en Tokens',
        BENEFIT_9: 'Todos los beneficios de Basic +',
      },
    },
  },
  GROUPS: {
    ERROR: {
      DUPLICATE_EMAIL: 'Hay miembros con el mismo correo electrónico',
    },
    ACTION: {
      DELETE_GROUP:
        '¿Estás seguro de que deseas eliminar este grupo? Esta acción no se puede deshacer.',
      DELETE_MEMBER:
        '¿Estás seguro de que deseas eliminar a este invitado del grupo? Esta acción no se puede deshacer',
    },
  },
  USER: {
    PROFILE: {
      TAB: {
        MEMBERSHIPS: 'Membresías',
      },
      MEMBERSHIP: {
        TABLE: {
          FIRST_NAME: 'Nombre',
          LAST_NAME: 'Apellido',
          GENDER: 'Género',
          WEAPONS: 'Armas',
          LATERALITY: 'Lateralidad',
          COUNTRY: 'País',
          BIRTHDAY: 'Fecha de nacimiento',
          ORGANISM: 'Organismo',
          ORGANISM_TYPE: 'Tipo',
        },
        DETAILS: {
          LICENSE: 'Licencia',
          DUE_DATE: 'Vencimiento',
          STATUS: 'Estado',
          ACTIVE: 'Activa',
          INACTIVE: 'Inactiva',
        },
      },
    },
  },
};

const lang = {
  ...LITERALS,
  'Contact us': 'Contáctanos',
  'Web develop by': 'Web desarrollada por',
  Yes: 'Si',
  No: 'No',
  'Without results': 'Sin resultados',
  'Order by': 'Ordenar por',
  Newest: 'Más recientes',
  Oldest: 'Más antiguos',
  'Please input your email!': 'Por favor ingresa un Correo electrónico',
  'You must enter a valid Email address!':
    'Debes ingresar un Correo electrónico valido',
  Email: 'Correo electrónico',
  'Reset Password': 'Restablecer contraseña',
  Search: 'Buscar',
  Ok: 'Ok',
  'Video Replay FILE': 'Archivo de reproducción de video',
  'Please input your Password!': 'Por favor ingresa una contraseña!',
  Password: 'Contraseña',
  'Forgot Password?': '¿Olvidaste tu contraseña?',
  'Pre-Register': 'Pre-Registro',
  'Log In': 'Conectar',
  Create: 'Crear',
  'Get Premium': 'Hazte Premium',
  'Premium Account': 'Cuenta Premium',
  'Trial Version': 'Version de Prueba',
  'TO SHARE OR WATCH SHARED VIDEOS YOU NEED TO BE PREMIUM':
    'PARA COMPARTIR O VER VIDEOS COMPARTIDOS NECESITA SER PREMIUM',
  'WHAT IS PREMIUM?': 'QUE ES PREMIUM?',
  Notifications: 'Notificaciones',
  ErrorSuccessPaypal: 'Ocurrio un error al procesar el pago con PayPal',
  SuccessPaypal: 'El pago fue procesado correctamente',
  ErrorPayPaypal: 'El pago no fue realizado, vuelva a intentarlo',
  CancelPayPaypal: 'Pago no realizado, el usuario cancelo el proceso',
  Premium: 'Premium',
  'Free Trial': 'Prueba gratuita',
  'Buy Now': 'Comprar ahora',
  'My Profile': 'Mi perfil',
  'Change Password': 'Cambiar Password',
  'Legal warning': 'Aviso legal',
  'Log Out': 'Cerrar Sesión',
  'Are you sure? all the unsaved changes will be lost':
    '¿Estás seguro? todos los cambios no guardados se perderán',
  Exit: 'Salir',
  Save: 'Guardar',
  Public: 'Público',
  Private: 'Privado',
  'This field is required!': '¡Este campo es obligatorio!',
  Name: 'Nombre',
  Group: 'Grupo',
  'Time Controls': 'Controles de tiempo',
  'Playback Rate': 'Vel. de reproducción',
  Simple: 'Simple',
  Off: 'Off',
  On: 'On',
  Cover: 'Portada',
  Category: 'Categoría',
  Tags: 'Etiquetas',
  'Created At': 'Creado El',
  Visibility: 'Visibilidad',
  "You can't add a duplicated tag unless you add a new protagonist first":
    'No se puede añadir una etiqueta duplicada a menos que se añada un protagonista primero',
  'only two tags/protagonist can be concatenated':
    'sólo se pueden concatenar dos etiquetas/protagonistas',
  'You have to create an action before concatenating tags':
    'Tienes que crear una acción antes de concatenar las etiquetas',
  'You have to add a tag or protagonist first':
    'Tienes que añadir una etiqueta o un protagonista primero',
  Country: 'País',
  'First Name': 'Nombre',
  'Last Name': 'Apellido',
  'Password must have at least 8 character and contain at least one number':
    'La contraseña debe tener al menos 8 caracteres y contener al menos un número',
  'Password Confirmation': 'Confirmación de Contraseña',
  'Password must match!': 'las Contraseñas deben coincidir!',
  'Please enter a invitation code':
    'Por favor ingrese un código de invitación válido, o deje en blanco',
  'Invitation Code': 'Código de invitación (NO OBLIGATORIO)',
  'Invitation code is not valid': 'El código de invitación no es válido',
  'Already have an account?': '¿Ya tienes una cuenta?',
  'Create a skouting account': 'Crear una cuenta de skouting',
  'Login to skouting': 'Iniciar sesión en skouting',
  'Go Back': 'Volver Atrás',
  Sports: 'Deportes',
  Others: 'Otros',
  Asc: 'Asc',
  Desc: 'Desc',
  'All Sports': 'Todos los Deportes',
  Videos: 'Videos',
  'My Scoutings': 'Mis Skoutings',
  Scoutings: 'Skoutings',
  'Scoutings Shared with me': 'Skoutings Compartidos Conmigo',
  Hi: 'Hola',
  Total: 'Total',
  'Your payment was not approved': 'Su pago no fue aprobado',
  'Please try again with another card or a different payment method':
    'Por favor, inténtelo de nuevo con otra tarjeta o un método de pago diferente.',
  'Your account has been activated': 'Su cuenta ha sido activada',
  'You can now access all the features of a Premium user':
    'Ahora puede acceder a todas las funciones de un usuario Premium',
  'Visit our FAQs for more information':
    'Visite nuestras Preguntas frecuentes para obtener más información',
  'Video Replay BASIC': 'Reproducción de Video BÁSICA',
  Monthly: 'Mensual',
  Annual: 'Anual',
  Permanent: 'Permanente',
  'Software license': 'Licencia de software',
  'Already had a free license': 'Ya tuvo una licencia gratuita',
  Continue: 'Continuar',
  '13.99 € / month * 1 month': '13.99 € / mes * 1 mes',
  '4.99 € / month * 12 months (You save 108 €)':
    '4,99 € / mes * 12 meses (ahorras 108 €)',
  'Redeem a code': 'Canjear un cupón',
  'Enter your coupon code below':
    'Introduzca su código de cupón a continuación',
  Redeem: 'Canjear',
  'Free trial (15 Days)': 'Prueba gratuita (15 días)',
  'Free trial': 'Prueba gratuita',
  Home: 'Inicio',
  'Do you want to remove this action?': '¿Quieres eliminar esta acción?',
  'No comments yet!': 'Aún no hay comentarios!',
  'Write a comment': 'Escribir comentario',
  'Internal Share': 'Compartir interno',
  Actions: 'Acciones',
  "You haven't shared this scouting yet, you can invite others user by adding their Email in the box bellow":
    'No has compartido este skouting todavía, puedes invitar a otros usuarios agregando su Email en el input de abajo.',
  Share: 'Compartir',
  'Do you want to unpublish the scouting?':
    '¿Quieres anular la publicación del Skouting?',
  'Do you want to publish the scouting?': '¿Quieres publicar el Skouting?',
  'You must be logged in to use this feature':
    'Debes inicia sessión para usar esta funcionalidad',
  'You must have a premium license to use this feature':
    'Debes tener una licencia premium para usar esta funcionalidad',
  Protagonist: 'Protagonista',
  Protagonists: 'Protagonistas',
  Color: 'Color',
  'No colors available': 'No hay colores disponibles',
  Selection: 'Selección',
  Filters: 'Filtros',
  'Advanced filters': 'Filtros avanzados',
  Apply: 'Aplicar',
  'Filter by tag': 'Filtrar por etiqueta',
  'No options': 'No hay opciones',
  'Select Tags': 'Seleccionar Etiquetas',
  'Filter by color': 'Filtrat por color',
  Title: 'Título',
  'Write your Description': 'Escribe una descripción',
  'Start Time': 'Inicio',
  'End time': 'Fin',
  'End time must be after start time': 'Fin debe ser despues del inicio',
  'No actions for the selected filters':
    'No hay acciones para los filtros seleccionados',
  'Start creating actions': 'Empieza a crear acciones',
  'No actions in this skouting yet': 'No hay acciones en este skouting todavía',
  Edit: 'Editar',
  Remove: 'Eliminar',
  Minutes: 'Minutos',
  'Please process the payment of your invoice':
    'Por favor procese el pago de su factura',
  Receipt: 'Recibo',
  Algorithm: 'Algoritmo',
  'Applied to': 'Aplicado a',
  Clear: 'Limpiar',
  Checkout: 'Pagar',
  'Add algorithm': 'Agregar algoritmo',
  'Add all algorithms': 'Agregar todos los algoritmos',
  selected: 'seleccionado',
  Select: 'Seleccione',
  Specialty: 'Especialidad',
  'Apply to': 'Aplicar a',
  All: 'Todo',
  Filtered: 'Filtrado',
  Time: 'Tiempo',
  From: 'Desde',
  To: 'A',
  'Search Actions': 'Buscar Acciones',
  Date: 'Fecha',
  Description: 'Descripción',
  'Used Tags': 'Etiquetas Usadas ',
  Comments: 'Comentarios',
  Statistics: 'Estadísticas',
  'Please login to enjoy this feature':
    'For favor inicia sesión para disfrutar esta función',
  'Something went wrong, please try again later.':
    'Algo salió mal, por favor, inténtelo de nuevo más tarde.',
  'Scouting Statistics': 'Estadísticas del Skouting',
  'Global Comments': 'Comentarios Globales',
  'Actions Comments': 'Comentarios de Acciones',
  'Count By Tags': 'Recuento por etiquetas',
  'No selected actions': 'No hay acciones seleccionadas',
  Start: 'Inicio',
  End: 'Fin',
  Cancel: 'Cancelar',
  'No Title': 'Sin Título',
  Duration: 'Duración',
  'Quick off': 'Quick off',
  'Quick On': 'Quick On',
  'Skouting link copied to the clipboard':
    'el link al skouting se copio en el portapapeles',
  'Make public': 'Publicar',
  'Share on Facebook': 'Compartelo en Facebook',
  'Checkout this skouting': 'Mira este skouting',
  'Share on Twitter': 'Compartelo en Twitter',
  'Copy link to clipboard': 'Copia el link al portapapeles',
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  Copy: 'Copiar',
  'Share by mail': 'Compartir por correo',
  'Share on Whatsapp': 'Compartelo en Whatsapp',
  'Share on Telegram': 'compartelo en Telegram',
  'Make private': 'Hacer Privado',
  'Max size of 2MB': 'Tamaño maximo de 2MB',
  'Update Scouting': 'Actualizar Skouting',
  'New Scouting': 'Nuevo Video',
  'Please fill the required information':
    'Por favor, rellene la información requerida',
  Sport: 'Deporte',
  'Video Source': 'Fuente del vídeo',
  'Video Time': 'Tiempo de video',
  'You can crop the video specifying a start and end time with the slider':
    'Puedes cortar el video especificando tiempos de inicio y fin con el slider',
  'Write your description': 'Escribe tu descripción',
  'Video Referee File': 'Archivo Video Referee',
  Optional: 'Opcional',
  'You can upload a compatible video referee file to preload the scouting actions':
    'Puedes subir un archivo de video referee compatible para precargar las acciones del Skouting',
  'Published at': 'Publicado el',
  'Password updated successfully': 'Contraseña actualizada exitosamente',
  'Current Password': 'Contraseña Actual',
  'New Password': 'Nueva contraseña',
  'New Password Confirmation': 'Confirmación de la nueva contraseña',
  'Group name': 'Nombre del grupo',
  Members: 'Miembros',
  Update: 'Actualizar',
  'Nº Of Members': 'Nº De Miembros',
  'Nº of Shared Skoutings': 'Nº de Skoutings Compartidos',
  Modify: 'Modificar',
  'Create New': 'Crear Nuevo',
  'Profile updated successfully': 'Perfil actualizado satosfactoriamente',
  'Allowed JPG, GIF or PNG': 'Extensiones Permitidas JPG, GIF o PNG',
  'Profile Image updated successfully':
    'Imagen de perfil actualizada correctamente',
  Day: 'Dia',
  Month: 'Mes',
  Year: 'Año',
  Female: 'Mujer',
  Male: 'Hombre',
  Other: 'Otro',
  'Change Avatar': 'Cambiar avatar',
  Gender: 'Género',
  'Date of Birth': 'Fecha de nacimiento',
  'When does the license expire?': '¿Cuando expira la licencia?',
  revokeMessage:
    'Pretende revocar su API KEY, esto implica que todos los equipos que estén configurados con esta API KEY deberán indicar una nueva API KEY para funcionar correctamente. Si pulsa cancelar la API KEY no cambiará. Si pulsa ACEPTAR una nueva API KEY será generada y no podrá volver a revocar hasta dentro de 48 horas',
  'Api Key Code': 'Código de clave de API',
  'Api Key': 'Api Key',
  Revoke: 'Revocar',
  Renew: 'Renovar',
  'Are you sure you want to remove the selected scoutings? this cannot be undone':
    '¿Está seguro de que quiere eliminar los skoutings seleccionados? Esto no se puede deshacer.',
  'Are you sure you want to remove this scouting? this cannot be undone':
    '¿Estás seguro de que quieres quitarte este skouting? Esto no se puede deshacer.',
  Delete: 'Eliminar',
  'Bulk Delete': 'Borrar en masa',
  'Selected algorithms': 'Algoritmos seleccionados',
  'Payed ID': 'ID de pago',
  'Payment type': 'Tipo de pago',
  Status: 'Estado',
  'Scouting ID': 'Skouting ID',
  'Are you sure you want to remove the selected protagonists? this cannot be undone':
    '¿Está seguro de que quiere eliminar a los protagonistas seleccionados? Esto no se puede deshacer.',
  'Are you sure you want to remove this protagonist? this cannot be undone':
    '¿Estás seguro de que quieres eliminar a este protagonista? Esto no se puede deshacer.',
  'Quick action': 'Acción rápida',
  'Add tags': 'Añadir etiquetas',
  'Are you sure you want to remove the selected tags? this cannot be undone':
    '¿Estás seguro de que quieres eliminar las etiquetas seleccionadas? Esto no se puede deshacer.',
  'Are you sure you want to remove this tag? this cannot be undone':
    '¿Estás seguro de que quieres eliminar esta etiqueta? Esto no se puede deshacer.',
  'By Group': 'Por Grupo',
  'Show All': 'Mostrar Todo',
  See: 'Ver',
  'My profile': 'Mi Perfil',
  Information: 'Información',
  'Quick actions': 'Acciones rápidas',
  Groups: 'Grupos',
  Licenses: 'Licencias',
  Payments: 'Pagos',
  Replay: 'Repetición',
  'Choose a video to create a personalised analysis and save your best moments':
    'Elige un video para crear un análisis personalizado y guardar tus mejores momentos',
  'Have better control over your video, choose the moments relevant to you and watch them back at your own speed':
    'Tenga un mejor control sobre su video, elija los momentos relevantes para usted y mírelos a su propia velocidad',
  'Share your best moments with friends and family, share your progress privately with your coach or trainer':
    'Comparta sus mejores momentos con amigos y familiares, comparta su progreso en privado con su entrenador o entrenador',
  'Log in and pay': 'Iniciar sesión y pagar',
  Register: 'Registrarse',
  'Register and pay': 'Regístrese y pague',
  'Follow us': 'Siguenos',
  'Thank you for buying Skouting Video Replay Software':
    'Gracias por comprar el software de reproducción de video Skouting',
  'Download your software now': 'Descargue su software ahora',
  Download: 'Descargar',
  'License code': 'Código de licencia',
  'Upgrade your skouting account for free':
    'Actualice su cuenta de skouting gratis',
  Until: 'Hasta',
  '20% discount on RRP': '20% de descuento en RRP',
  Renewal: 'Renovación',
  'Group invitations': 'Invitación a grupos',
  'Host name': 'Nombre de anfitrión',
  Accept: 'Aceptar',
  'Are you sure to cancel the invitation':
    'Esta seguro de cancelar la invitación',
  'Are you sure to accept the invitation':
    'Esta seguro de aceptar la invitación',
  'Who can tag you in the skoutings':
    'Quién puede etiquetarte en las Skoutings',
  'Who can see your statistics for Skouting':
    'Quién puede ver tus estadísticas de Skouting',
  Accepted: 'Aceptada',
  Sent: 'Enviada',
  Denied: 'Negada',
  Member: 'Miembro',
  'Acceptance date': 'Fecha de aceptación',
  'Invitation status': 'Estado de la invitación',
  'Group Owner': 'Propietario del grupo',
  'All members of the group': 'Todos los miembros del grupo',
  'Group details': 'Detalles del grupo',
  'Groups to which I belong': 'Grupos a los que pertenezco',
  'You want to allow the skoutings in which you are tagged to be made public':
    'Quieres permitir que las skoutings en las que estás etiquetado se hagan públicas',
  Username: 'Nombre de usuario',
  'Username already exist': 'El nombre de usuario ya existe',
  UsernameRegex:
    'Solo puede obtener los siguientes caracteres (_-.) y no puede tener espacios en blanco, y al principio debe tener @',
  TAX: 'IVA',
  'View Profile': 'Ver Perfil',
  'Advanced Filters': 'Filtros Avanzados',
  'Advanced Actions': 'Acciones Avanzadas',
  'Share videos privately': 'Comparte videos de forma privada',
  '20% discount on AI': '20% de descuento en IA',
  Identification: 'Identificación',
  'Tag Name': 'Nombre de etiqueta',
  'Protagonist Name': 'Nombre del protagonista',
  'Nº of Occurrences': 'Nº de apariciones',
  Add: 'Agregar',
  'No Tags': 'No hay etiquetas',
  'Start Action': 'Iniciar acción',
  'Stop Action': 'Detener acción',
  'Send Invite': 'Enviar invitación',
  'Share With Groups': 'Compartir con Grupos',
  'Shared With': 'Compartido con',
  'Share with selected groups': 'Compartir con grupos seleccionados',
  'Are you sure you want to remove this guest from the group? this cannot be undone':
    '¿Estás seguro de que deseas eliminar este invitado del grupo? esto no se puede deshacer',
  'Get basic': 'Comprar plan basico',
  'Get premium': 'Comprar plan premium',
  'Get expert': 'Comprar plan experto',
  'Get credits': 'Añadir más créditos',
  'days left': 'Días restantes',
  'YOU NEED TO BE LOGGED IN TO SEE THIS SKOUTING':
    'TIENE QUE INICIAR SESIÓN PARA VER ESTE SKOUTING',
  'Subscribe to a Plan': 'Suscríbete a un Plan',
  "DON'T HAVE AN ACCOUNT YET?": 'AÚN NO TIENE UNA CUENTA?',
  'REGISTER NOW': 'REGÍSTRESE',
  'Activate loop': 'Activar bucle',
  Volume: 'Volumen',
  'your identity inside Skouting': 'tu identidad dentro de Engarde Videos',
  'Trial Account': 'Actualice su cuenta,',
  'Access fencing video database': 'Acceso a la BD de videos de esgrima',
  'Create Skoutings': 'Crear Video Análisis',
  'Watch, comment and share': 'Mirar, comentar y compartir',
  'Advanced statistics': 'Estadísticas avanzadas',
  'Create groups': 'Crear grupos',
  'With 15 days Skouting trial you can':
    'Con 15 días de prueba de Engarde Videos podrás',
  'The prime platform where users access, analyze and share online videos of fencing tournaments':
    'La primera plataforma donde los usuarios acceden, analizan y comparten vídeos online de torneos de esgrima',
  Fencing: 'Esgrima',
  'Video \n technology': 'Video \n tecnología',
  'fencing \n results': 'resultados \n esgrima',
  'Video \n analysis': 'Video \n análisis',
  'we are developing the new \n technology for fencing':
    'estamos desarrollando la nueva \n tecnología para esgrima',
  'are you ready for it?': 'estás preparado para esto?',
  Categories: 'Categorias',
  Weapon: 'Arma',
  Phase: 'Fase',
  'Type of competition': 'Tipo de competición',
  Tournament: 'Torneo',
  Top: 'Top',
  Discipline: 'Disciplina',
  Filter: 'Filtro',
  'Filter applied': 'Filtro aplicado',
  Position: 'Posición',
  State: 'Estado',
  Result: 'Resultado',
  'No data': 'Sin datos',
  'ARE YOU SURE YOU WANT TO DELETE THIS CATEGORY? THIS CANNOT BE UNDONE':
    '¿ESTÁS SEGURO QUE QUIERES ELIMINAR ESTA CATEGORÍA? ESTO NO SE PUEDE DESHACER',
  'Category name': 'Nombre de la categoría',
  Successful: 'Exitoso',
  FOOTER_SOFTWARE: 'SOFTWARE',
  FOOTER_HARDWARE: 'HARDWARE',
  FOOTER_SERVICES: 'SERVICiOS',
  FOOTER_EVENTS: 'EVENTOS',
  FOOTER_DEVELOPMENT: 'DESARROLLO',
  FOOTER_TECHNICAL_SUPPORT_TRAINING: 'SOPORTE Y ENTRENAMIENTO',
  FOOTER_SOLUTIONS: 'SOLUCIONES',
  FOOTER_COMPETITION: 'COMPETITION',
  FOOTER_PERFORMANCE: 'PERFORMANCE',
  FOOTER_INDUSTRY: 'INDUSTRIA',
  FOOTER_FOOTBALL: 'FÚTBOL',
  FOOTER_SURVEILLANCE: 'VIGILANCIA',
  FOOTER_ABOUT_US: 'NOSOTROS',
  FOOTER_WHO_WE_ARE: 'QUIENES SOMOS',
  FOOTER_CONTACT: 'CONTACTO',
  FOOTER_TERMS_AND_CONDITIONS: 'TÉRMINOS Y CONDICIONES',
  FOOTER_PRIVACY_POLICY: 'POLÍTICA DE PRIVACIDAD',
  'Buy SK': 'Añadir más créditos',
  'one-time payment': 'pago por 5000 créditos',
  'Your SK Tokens': 'Tus Tokens SK',
  Algorithms: 'Algoritmos',
  subscribers: 'subscriptores',
  'Mi Canal': 'Mi Canal',
  Subscribe: 'Suscribirse',
  Suscrito: 'Suscrito',
  Publish: 'Publicar',
  'Channel description': 'Descripción del canal',
  Personalizar: 'Personalizar',
  'Click to upload': 'Haz click para subir',
  'or drag and drop': 'o arrastra aquí',
  'SVG, PNG or JPG': 'SVG, PNG o JPG',
  View: 'Ver',
  Generate: 'Generar',
  basic_monthly: '2,99 € / mes',
  basic_annual: '19,99 € / año',
  premium_monthly: '6,99 € / mes',
  premium_annual: '59,88 € / año',
  expert_monthly: '34,99 € / mes',
  expert_annual: '144,99 € / año',
  license_annual: '137,99 € / año',

  credits_fixed: '5,00 € / pago por 5000 créditos',
  credits_fixed_hidden: '5,00 €',

  credits_fixed_10k: '10,00 € / pago por 10000 créditos',
  credits_fixed_hidden_10k: '10,00 €',
  credits_fixed_50k: '50,00 € / pago por 50000 créditos',
  credits_fixed_hidden_50k: '50,00 €',
  credits_fixed_100k: '100,00 € / pago por 100000 créditos',
  credits_fixed_hidden_100k: '100,00 €',
  credits_fixed_500k: '500,00 € / pago por 500000 créditos',
  credits_fixed_hidden_500k: '500,00 €',
  credits_fixed_1M: '1000,00 € / pago por 1000000 créditos',
  credits_fixed_hidden_1M: '1000,00 €',

  fixed_storage_1GB: '2,00 € / pago por 1 GB',
  fixed_storage_10GB: '5,00 € / pago por 10 GB',
  fixed_storage_100GB: '20,00 € / pago por 100 GB',
  fixed_storage_1TB: '50,00 € / pago por 1 TB',
  STORAGE: 'Almacenamiento',
  'add-credits': 'añadir más almacenamiento',
  subscription_modal_title: 'Subscripción vencida',
  subscription_modal_text:
    'Su subscripción se venció y no ha sido posible realizar el cobro con su actual metodo de pago, por favor realice el pago con un nuevo método de pago o inicie el proceso para darse de baja, nuestro equipo de soporte se pondrá en contacto con usted para que realice la descarga de todos sus archivos multimedia.',
  download_process_button_text: 'Iniciar proceso de descarga',
  go_to_payment: 'Ir a pagar',
  after_message:
    'En las próximas horas, nuestro equipo de soporte se contactará con usted',
};

export default lang;
